import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Polygon, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const ChangeView = ({ center }) => {
  const map = useMap();
  
  useEffect(() => {
    map.setView(center, map.getZoom());
  }, [center, map]);

  return null;
};

const MiniMap = ({ coordinates }) => {

  const [center, setCenter] = useState(L.polygon(coordinates).getBounds().getCenter());

  useEffect(() => {
    setCenter(L.polygon(coordinates).getBounds().getCenter());
  }, [coordinates]);

  return (
      <MapContainer center={center} zoom={6} scrollWheelZoom={false} style={{ height: '200px', width: '400px', marginBottom: '10px'}}>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Polygon positions={coordinates} pathOptions={{ color: 'red' }}/>
        <ChangeView center={center}/>
      </MapContainer>
  );
};

export default MiniMap;
