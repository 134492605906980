import { formatISO } from 'date-fns';

const ERROR_MSG_GET_PRODUCTS = "Failed to fetch products. Please try again later or contact an administrator.";
const ERROR_MSG_SEARCH_PRODUCTS = "Failed to fetch products. Please verify your search criteria or try again later.";
const ERROR_MSG_POST_PRODUCT = "Failed to request product. Please make sure the requested product is a valid Sentinel-1 GRD product.";
const ERROR_MSG_DOWNLOAD_PRODUCT = "Failed to download product."

class RtcApiService {

  static getProducts = async () => {
    try {
      const response = await fetch(`/api/products`);
      const data = await response.json();

      if (!response.ok) {
        return { data: [], error: ERROR_MSG_GET_PRODUCTS}
      }

      return { data: data.products, error: null };
    } catch (error) {
      return { data: [], error: ERROR_MSG_GET_PRODUCTS }
    }
  }

  static getSearchProducts = async (dateFrom, dateTo) => {
    try {
      const formattedDateFrom = formatISO(new Date(dateFrom), { representation: 'date' });
      const formattedDateTo = formatISO(new Date(dateTo), { representation: 'date' });
      const response = await fetch(`/api/search?date_from=${formattedDateFrom}&date_to=${formattedDateTo}`);
      const data = await response.json();

      if (!response.ok) {
        return { data: [], error: ERROR_MSG_SEARCH_PRODUCTS}
      }

      return { data: data.products, error: null };
    } catch (error) {
      return { data: [], error: ERROR_MSG_SEARCH_PRODUCTS }
    }
  }

  static downloadProduct = async (identifier) => {
    try {
      const response = await fetch(`/api/product/${identifier}`, {
        method: 'GET',
      });

      if (!response.ok) {
        return { data: null, error: ERROR_MSG_DOWNLOAD_PRODUCT };
      }

      const blob = await response.blob();
      
      const downloadUrl = window.URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = identifier + ".zip";
      document.body.appendChild(a); 
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);
      return { data: null, error: null };
    } catch (error) {
      return { data: null, error: ERROR_MSG_DOWNLOAD_PRODUCT };
    }
  };

  static postProduct = async (identifier) => {
    try {
      const response = await fetch(`/api/product/${identifier}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        return { data: null, error: ERROR_MSG_POST_PRODUCT };
      }

      return { data: null, error: null };

    } catch (error) {
      return { data: null, error: ERROR_MSG_POST_PRODUCT };
    }
  }

};

export default RtcApiService;
