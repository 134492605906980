import * as React from 'react';
import Typography from '@mui/joy/Typography';
import muniLogo from '../resources/muni_logo.jpeg';
import czuLogo from '../resources/czu_logo.png';
import copernicusLogo from '../resources/copernicus_logo.png';
import cspLogo from '../resources/csp_logo.png';

export default function About() {
  return (
    <div>
        <h1 style={{textAlign: 'center'}}>About</h1>
        <Typography id="modal-desc" textColor="text.tertiary" sx={{ 
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
        }}>
          This app provides ready-to-use, corrected Sentinel-1 GRD products for the Czech Republic. Users can download processed products right away or search and request specific Sentinel-1 GRD products if they're not already available. Corrections are done with SNAP for accurate results.
          This app was developed with the Institute of Computer Science at Masaryk University and the Czech University of Life Sciences, supported by the CollGS initiative.
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={muniLogo} alt="Logo of Masaryk University" style={{ width: '160px', height: '50px', marginRight: '20px' }} />
        <img src={czuLogo} alt="Logo of Czech University of Life Sciences" style={{ width: '250px', height: '80px', marginRight: '20px' }} />
        <img src={copernicusLogo} alt="Logo of Copernicus Programme" style={{ width: '160px', height: '40px', marginRight: '20px'  }} />
        <img src={cspLogo} alt="Logo of Czech Space Portal" style={{ width: '100px', height: '50px', marginRight: '20px'  }} />
        </div>
    </div>
  );
}
