import React from 'react';
import MiniMap from './MiniMap';
import { Grid, Card, Typography, CardContent, Button } from '@mui/joy';
import { format, parseISO } from 'date-fns';
import ActionButton from './ActionButton';
import Alert from '@mui/joy/Alert';
import { ProductStatus } from '../constants/ProductStatus';

const MapGrid = ({ products }) => {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd MMMM, yyyy');
  };

  const formatTime = (dateString) => {
    const date = parseISO(dateString);
    return format(date, 'HH:mm:ss');;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case ProductStatus.NOT_PROCESSED:
        return 'secondary'
      case ProductStatus.QUEUED:
        return 'warning'
      case ProductStatus.IN_PROGRESS:
        return 'primary'
      case ProductStatus.ERROR:
        return 'danger'
      case ProductStatus.FINISHED:
        return 'success'
      default:
        return 'secondary'
    }
  }

  return (
    <div style={{paddingTop: '3rem'}}>
        <Grid container justifyContent="flex-start" spacing={2}>
            {products.map((data, index) => (
                <Card key={index} sx={{ width: 400, marginBottom: 4, marginLeft: 2 }}>
                    <div>
                      <h3 style={{margin: 0, padding: 0}}>{formatDate(data.date)}</h3>
                      <h5 style={{margin: 0, padding: 0}}>{formatTime(data.date)}</h5>
                    </div>
                    <div>
                      <MiniMap coordinates={data.coordinates}/>
                    </div>
                    <div>
                      <Typography level="body-sm" sx={{ 
                          wordBreak: 'break-all',
                          overflowWrap: 'break-word',
                          hyphens: 'auto'
                      }}>
                          {data.identifier}
                      </Typography>
                    </div>
                    <div>
                      <ul>
                          <li>Product Type: {data.productType}</li>
                          <li>Mode: {data.mode}</li>
                          <li>Sensing Date: {formatDate(data.date)} {formatTime(data.date)}</li>
                          <li>Polarisation: {data.polarisation}</li>
                          <li>Format: {data.format}</li>
                          <li>Product Level: {data.productLevel}</li>
                      </ul>
                    </div>
                    <CardContent orientation="horizontal">
                      <div>
                          <Typography level="body-xs"> {data.status === ProductStatus.FINISHED ? "Processed" : "Original"} Size:</Typography>
                          <Typography fontSize="lg" fontWeight="lg">
                            {data.status === ProductStatus.FINISHED ? data.processedSize : data.size}
                          </Typography>
                      </div>
                      <Alert size="md" color={getStatusColor(data.status)}>
                        <Typography fontSize="md" fontWeight="lg">
                          {data.status}
                        </Typography>
                      </Alert>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                        <ActionButton identifier={data.identifier} status={data.status} />
                      </div>
                    </CardContent>
                </Card>
            ))}
        </Grid>
    </div>
  );
};

export default MapGrid;
