import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/joy';
import CheckIcon from '@mui/icons-material/Check';

const FadeAlert = ({ message, color }) => {
  const [animationStyle, setAnimationStyle] = useState('fadeIn 0.5s ease');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAnimationStyle('fadeOut 0.5s ease');
    }, 4500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div style={{ position: 'fixed', top: 20, right: 20, width: '30%', minWidth: '300px', zIndex: 9999999}}>
      <Alert
        style={{ animation: animationStyle, animationFillMode: 'forwards'}} variant="soft" color={color} startDecorator={<CheckIcon />}>
        {message}
      </Alert>
    </div>
  );
};

export default FadeAlert;
