import React, { useEffect, useState } from 'react';
import RtcApiService from '../services/RtcApiService';
import { ProductStatus } from '../constants/ProductStatus';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import FadeAlert from './FadeAlert';

const ActionButton = ({ identifier, status }) => {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [action, setAction] = useState(() => () => {});

  const ALERT_DURATION_MS = 5000;
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertColor, setAlertColor] = useState("primary");

  const displayAlert = (msg, color) => {
    setAlertMessage(msg);
    setAlertColor(color);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage('');
      setAlertColor("primary");
    }, ALERT_DURATION_MS);
  }

  useEffect(() => {
    switch (status) {
      case ProductStatus.NOT_PROCESSED:
        setLabel("Request");
        setDialogText("Are you sure you want to request this product?");
        setIsDisabled(false);
        setAction(() => async () => {
          const { error } = await RtcApiService.postProduct(identifier);
          displayAlert(
            error ? error : "Product requested successfully",
            error ? "danger" : "success"
          );
        });
        setShowModal(true);
        break;
      case ProductStatus.ERROR:
        setLabel("Retry Processing");
        setDialogText("Are you sure you want to retry processing of this product?");
        setIsDisabled(false);
        setAction(() => async () => {
          const { error } = await RtcApiService.postProduct(identifier);
          displayAlert(
            error ? error : "Product requested successfully",
            error ? "danger" : "success"
          );
        });
        setShowModal(true);
        break;
      case ProductStatus.QUEUED:
        setLabel("Download");
        setDialogText("Are you sure you want to download this product?");
        setIsDisabled(false);
        setAction(() => () => RtcApiService.downloadProduct(identifier));
        setShowModal(false);
        break;
      case ProductStatus.IN_PROGRESS:
        setLabel("Download");
        setDialogText("Are you sure you want to download this product?");
        setIsDisabled(true);
        setAction(() => () => RtcApiService.downloadProduct(identifier));
        setShowModal(false);
        break;
      case ProductStatus.FINISHED:
        setLabel("Download");
        setDialogText("Are you sure you want to download this product?");
        setIsDisabled(false);
        setAction(() => () => RtcApiService.downloadProduct(identifier));
        setShowModal(false);
        break;
      default:
        setLabel("Download");
        setDialogText("Are you sure you want to download this product?");
        setIsDisabled(true);
        setAction(() => () => RtcApiService.downloadProduct(identifier));
        setShowModal(false);
    }
  }, [status, identifier]);

  return (
    <React.Fragment>
      {showAlert && <FadeAlert message={alertMessage} color={alertColor} />}

      {status === ProductStatus.FINISHED ? (
      <a href={`/api/product/${identifier}`}>
        <Button 
          disabled={isDisabled} 
          onClick={showModal ? () => setOpen(true) : () => action()} 
          variant="solid" 
          size="lg" 
          color="primary" 
          aria-label={label} 
          sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600, height: '4' }}>
          {label}
        </Button>
      </a>
    ) : (
      <Button 
        disabled={isDisabled} 
        onClick={showModal ? () => setOpen(true) : () => action()} 
        variant="solid" 
        size="lg" 
        color="primary" 
        aria-label={label} 
        sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600, height: '4' }}>
        {label}
      </Button>
    )}

      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            {dialogText}
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="primary" onClick={() => { action(); setOpen(false); }}>
              Confirm
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
};

export default ActionButton;
