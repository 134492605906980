import React from 'react';
import enviLogo from '../resources/envilab_logo.svg';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';

const Menu = ({ changeTab }) => {
  return (
    <Box component="nav" aria-label="My site" sx={{ flexGrow: 1 }}>
      <List role="menubar" orientation="horizontal">
        <ListItem role="none">
          <a href='https://envilab.cerit-sc.cz/en-gb'>
            <img src={enviLogo} alt="Logo of EnviLab" style={{ width: '180px', height: '50px' }} />
          </a>
        </ListItem>
        <ListDivider />
        <ListItem role="none">
          <ListItemButton role="menuitem" component="a" onClick={() => changeTab("download-products")}>
            Download Products
          </ListItemButton>
        </ListItem>
        <ListDivider />
        <ListItem role="none">
          <ListItemButton role="menuitem" component="a" onClick={() => changeTab("request-products")}>
            Request Products
          </ListItemButton>
        </ListItem>
        <ListDivider />
        <ListItem role="none">
          <ListItemButton role="menuitem" component="a" onClick={() => changeTab("about")}>
            About
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default Menu;
