import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/joy';
import '../App.css';
import ProductsFilter from './ProductsFilter';
import MapGrid from './MapGrid';
import RtcApiService from '../services/RtcApiService';

const SearchProductsTab = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [products, setProducts] = useState([]);

    const fetchProducts = async (fromDate, toDate) => {
        setIsLoading(true);
        setError(null);
        const { data, error } = await RtcApiService.getSearchProducts(fromDate, toDate);
        setError(error ? error : null);
        setProducts(error ? [] : data);
        setIsLoading(false);
    };

    return (
        <div>
            <h1 style={{textAlign: 'center'}}>Request Sentinel-1 GRD Products</h1>
            <ProductsFilter fetchProducts={fetchProducts} style={{margin: '10px'}}/>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </div>
            ) : error ? (
                <div style={{ textAlign: 'center', padding: '50px 0px' }}>
                    {error}
                </div>
            ) : products.length === 0 ? (
                <div style={{ textAlign: 'center', padding: '50px 0px'}}>
                    No results found. Please try adjusting your search filters.
                </div>
            ) : (
                <MapGrid products={products}/>
            )}
        </div>
    );
};

export default SearchProductsTab;
