import React, { useState } from 'react';
import './App.css';
import Menu from './components/Menu';
import About from './components/About';
import AvailableProductsTab from './components/AvailableProductsTab';
import SearchProductsTab from './components/SearchProductsTab';

const App = () => {
  const [activeTab, setActiveTab] = useState('products');

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'download-products':
        return <AvailableProductsTab/>;
      case 'request-products':
        return <SearchProductsTab/>;
      case 'about':
        return <About/>;
      default:
        return <AvailableProductsTab/>;
    }
  };

  return (
    <div className="container">
      <div className="main-content">
        <div style={{ textAlign: 'center' }}>
          <Menu changeTab={changeTab}/>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default App;
