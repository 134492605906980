import { Grid, Button } from '@mui/joy';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useState, useEffect } from 'react';

const ProductsFilter = ({ fetchProducts }) => {

    const [fromDate, setFromDate] = useState(new Date("2023-09-01"));
    const [toDate, setToDate] = useState(new Date("2023-09-14"));

    useEffect(() => {
        fetchProducts(fromDate, toDate);
    }, []);

    const handleChangeFromDate = (newValue) => {
        setFromDate(newValue);
    };

    const handleChangeToDate = (newValue) => {
        setToDate(newValue);
    };

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="left">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item>
                <DatePicker
                    label="From"
                    value={fromDate}
                    onChange={handleChangeFromDate}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <div>
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                        </div>
                    )}
                />
            </Grid>
            <Grid item>
                <DatePicker
                    label="To"
                    value={toDate}
                    onChange={handleChangeToDate}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <div>
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                        </div>
                    )}
                />
            </Grid>
            <Grid item>
                <Button 
                    variant="solid"
                    size="md"
                    color="primary"
                    aria-label="Filter"
                    sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600, minHeight: '48px' }}
                    onClick={() => fetchProducts(fromDate, toDate)}
                >
                    Filter
                </Button>
            </Grid>
        </LocalizationProvider>
    </Grid>
  );
};

export default ProductsFilter;
