import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/joy';
import '../App.css';
import MapGrid from './MapGrid';
import RtcApiService from '../services/RtcApiService';

const AvailableProductsTab = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        setIsLoading(true);
        setError(null);
        const { data, error } = await RtcApiService.getProducts();
        setError(error ? error : null);
        setProducts(error ? [] : data);
        setIsLoading(false);
    };

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Download RTC Products</h1>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </div>
            ) : error ? (
                <div style={{ textAlign: 'center', padding: '30px 0px' }}>
                    {error}
                </div>
            ) : products.length === 0 ? (
                <div style={{ textAlign: 'center', padding: '30px 0px'}}>
                    No data available. Add products via "Request Products" section above.
                </div>
            ) : (
                <MapGrid products={products}/>
            )}
        </div>
    );
};

export default AvailableProductsTab;
